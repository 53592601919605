import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ErrorBoundary from "./error-boundary/ErrorBoundary"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"


ReactDOM.render(
	<React.Fragment>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.Fragment>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

